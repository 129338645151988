import { createSharedComposable } from '@vueuse/core'

// NOTE: use experimental.asyncContext once support for Cloudflare lands (see https://github.com/unjs/nitro/issues/1171)
// apparently this requires setting Cloudflare Workers nodejs_compat compatibility flag (see https://github.com/unjs/unenv/issues/280)
const _useDashboard = () => {
  const route = useRoute()
  const router = useRouter()
  const isOpenHelpSlideover = ref(false)
  const isOpenNotificationsSlideoverList = ref(false)

  defineShortcuts({
    'g-h': () => router.push('/'),
    'g-t': () => router.push('/tasks'),
    'g-n': () => router.push('/notifications'),
    'g-s': () => router.push('/settings'),
    '?': () => {
      isOpenHelpSlideover.value = true
    },
    'n': () => {
      isOpenNotificationsSlideoverList.value = true
    },
  })

  watch(
    () => route.fullPath,
    () => {
      isOpenHelpSlideover.value = false
      isOpenNotificationsSlideoverList.value = false
    },
  )

  return {
    isOpenHelpSlideover,
    isOpenNotificationsSlideoverList,
  }
}

export const useDashboard = createSharedComposable(_useDashboard)
